import React from "react";
import { connect } from "react-redux";

const OADC = ({}) => {
  return (
    <>
      <div className="unified-platform-area ptb-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                <img
                  src={require("../../../assets/images/products/products/Mobile_Wallet_Solutions.webp")}
                  alt="Image"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="unified-platform-content wow fadeInRight delay-0-4s">
                <div className="mb-30">
                  <p>
                    Globally, digital currencies are intended to give an
                    experience in handling as close to “real cash”
                  </p>
                  <ul>
                    <li>Withdraw from a bank / ATM and store in your wallet</li>
                    <li>Pay anyone, anytime and any amount as you need to</li>
                    <li>Collect change if needed</li>
                    <li>
                      Deposit residual cash back in your bank when you want.
                    </li>
                  </ul>
                  <p>
                    Unfortunately, current Digital Currency solutions are a far
                    cry delivering the above experience… its all 100% online,
                    with services only on a smart phone. We at Mobipay have
                    built and integrated our secure element based Digital
                    Currency solution giving an experience as close as possible
                    to “real cash” but digitally on a mobile, can be any mobile
                    Pay at a POS terminal or from Phone-to-phone over NFC / BT /
                    QR.
                  </p>
                  <p>
                    Talk to us for delivering a true digital cash experience to
                    your customers.
                  </p>
                </div>

                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(OADC);
