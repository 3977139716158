import MFIApplication from "../pages/products/applications/MFIApplication";
import EventTicketing from "../pages/products/applications/EventTicketing";
import NexgoUN20 from "../pages/products/hardware/NexgoUN20";
import SR50 from "../pages/products/hardware/SR50";
import SuperLead72ISM from "../pages/products/hardware/SuperLead72ISM";
import SuperLeadSE2102N from "../pages/products/hardware/SuperLeadSE2102N";
import SuperLeadSE4102 from "../pages/products/hardware/SuperLeadSE4102";
import Supermax7130NM from "../pages/products/hardware/Supermax7130NM";
import AutomaticParkingPayment from "../pages/products/products/AutomaticParkingPayment";
import CardTokenisation from "../pages/products/products/CardTokenisation";
import EVoucher from "../pages/products/products/EVoucher";
import MobileWallet from "../pages/products/products/MobileWallet";
import SecureBanking from "../pages/products/products/SecureBanking";
import TransitFareCollection from "../pages/products/products/TransitFareCollection";
import OADC from "../pages/products/products/OADC";

export const Products = [
  {
    small_description:
      "Unattended payment terminal that can assist you in providing a great self-service experience.",
    slug: "nexgo-un20",
    brochure: true,
    name: "Nexgo UN20",
    has_detail: false,
    Template: NexgoUN20,
    thumbnail: require("../assets/images/products/hardware/nexgo.png"),
    banner: null,
    categories: ["hardware", "hardware-vending-machine-payment-terminals"],
  },
  {
    small_description:
      "Fixed mount on screen 2D barcode reader with built in RS232 and USB Interface.",
    slug: "supermax-7130nm",
    brochure: true,
    name: "Supermax 7130NM",
    has_detail: false,
    Template: Supermax7130NM,
    thumbnail: require("../assets/images/products/hardware/superlead-7130.png"),
    banner: null,
    categories: ["hardware", "hardware-qr-scanners"],
  },
  {
    small_description:
      "Read printed barcodes, wrinkled codes, and low-contrast barcode with wide angle image sensor",
    slug: "superlead-72ism",
    brochure: true,
    name: "SuperLead 72ISM",
    has_detail: false,
    Template: SuperLead72ISM,
    thumbnail: require("../assets/images/products/hardware/superlead-72ism.png"),
    banner: null,
    categories: ["hardware", "hardware-qr-scanners"],
  },
  {
    small_description:
      "Fast mobile phone screen barcode reading with multiple language support.",
    slug: "superlead-se4102",
    brochure: true,
    name: "SuperLead SE4102",
    has_detail: false,
    Template: SuperLeadSE4102,
    thumbnail: require("../assets/images/products/hardware/se4102.png"),
    banner: null,
    categories: ["hardware", "hardware-qr-scanners"],
  },
  {
    small_description:
      "Fast mobile phone screen barcode reading with multiple language support.",
    slug: "superlead-se2102n",
    brochure: true,
    name: "SuperLead SE2102N",
    has_detail: false,
    Template: SuperLeadSE2102N,
    thumbnail: require("../assets/images/products/hardware/superlead-se2102n.png"),
    banner: null,
    categories: ["hardware", "hardware-qr-scanners"],
  },
  {
    small_description:
      "Small and portable, easy to operate mobile card reader.",
    slug: "sr50",
    brochure: true,
    name: "SR50",
    has_detail: false,
    Template: SR50,
    thumbnail: require("../assets/images/products/hardware/sr50.png"),
    banner: null,
    categories: ["hardware", "hardware-pos-terminals"],
  },
  {
    small_description:
      "A revolutionary way for consumers to access banking services using their feature phones.",
    slug: "secure-banking-on-feature-phones",
    brochure: true,
    name: "Secure Banking on Feature Phones",
    has_detail: true,
    Template: SecureBanking,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Secure_Banking_on_Feature_Phones_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "In-device card tokenization solution for m-commerce payments, a cutting-edge technology that provides a secure and convenient way for consumers to make mobile transactions.",
    slug: "card-tokenisation-and-m-commerce-payments-including-tapnpay",
    brochure: true,
    name: "Card Tokenisation and M-Commerce Payments Including TapNPay",
    has_detail: true,
    Template: CardTokenisation,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Card_Tokenisation_and_M-Commerce_Payments_Including_TapNPay_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "SMS-based e-voucher solution for mobile services, a convenient and secure way for consumers to purchase and redeem vouchers/ tokens / cash backs and other transactional services.",
    slug: "e-voucher-e-coupon-solutions",
    brochure: true,
    name: "E-voucher / E-coupon Solutions",
    has_detail: true,
    Template: EVoucher,
    thumbnail: null,
    banner: require("../assets/images/page-bg/E-voucher_E-coupon_Solutions_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "With an integrated NFC and QR ticketing solution, allowing passengers to simply tap their contactless payment card or mobile device to pay for their fare without the need for cash or tickets.",
    slug: "transit-fare-collection-solutions",
    brochure: true,
    name: "Transit Fare Collection Solutions",
    has_detail: true,
    Template: TransitFareCollection,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Transit_Fare_Collection_Solutions.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "Utilizes contactless technology such as RFID or NFC, allowing drivers to simply tap their contactless payment card or mobile device to pay for their parking without the need for cash or physical tickets.",
    slug: "automatic-parking-payment-solutions",
    brochure: true,
    name: "Automatic Parking Payment Solutions",
    has_detail: true,
    Template: AutomaticParkingPayment,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Automatic_Parking_Payment_Solutions_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "Make payments using QR code scanning / TapNPay or then a walletID, eliminating the need for cash for online / offline merchants",
    slug: "mobile-wallet-solutions",
    brochure: true,
    name: "Mobile Wallet Solutions",
    has_detail: true,
    Template: MobileWallet,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Mobile_Wallet_Solutions_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "Mobipay provides a secure digital currency solution that replicates the real cash experience on any mobile device, enabling seamless payments via POS, NFC, Bluetooth, or QR code.",
    slug: "offline-anonymous-digital-currencies",
    brochure: true,
    name: "Offline, Anonymous Digital Currencies",
    has_detail: true,
    Template: OADC,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Mobile_Wallet_Solutions_Banner.webp"),
    categories: ["products"],
  },
  {
    small_description:
      "Designed to empower micro finance industry and handle UPI AutoPay and EMI collect services, feature phone users can easilyaccess financial services like bill payments, money transfers, andmerchant payments, using multiple UPI Virtual Payment Addresses (VPA), bank accounts.",
    slug: "financial-inclusion-solutions-for-the-micro-finance-industry",
    brochure: true,
    name: "Financial Inclusion Solutions for the Micro Finance Industry",
    has_detail: true,
    Template: MFIApplication,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Financial_Inclusion_Solutions.webp"),
    categories: ["applications"],
  },
  {
    small_description:
      "Say goodbye to long lines and paper tickets, as our solution allows for seamless entry with just a tap of your contactless card or scan of your QR code ticket.",
    slug: "theme-park-event-ticketing",
    brochure: true,
    name: "Theme Park / Event Ticketing",
    has_detail: true,
    Template: EventTicketing,
    thumbnail: null,
    banner: require("../assets/images/page-bg/Theme_Park_Event_Ticketing_Banner.webp"),
    categories: ["applications"],
  },
];

export const getProducts = (categorySlug) => {
  return Products.filter(
    (item) => item.categories.indexOf(categorySlug) !== -1
  );
};

export const getProduct = (productSlug) => {
  return Products.find((item) => item.slug === productSlug);
};

export const getProductsApplications = () => {
  return Products.filter((item) => item.categories.indexOf("hardware") === -1);
};
