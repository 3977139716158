import React from "react";
import { connect } from "react-redux";

const MobileWallet = ({}) => {
  return (
    <>
      <div className="unified-platform-area ptb-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="unified-img two wow fadeInLeft delay-0-4s mr-50">
                <img
                  src={require("../../../assets/images/products/products/Mobile_Wallet_Solutions.webp")}
                  alt="Image"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="unified-platform-content wow fadeInRight delay-0-4s">
                <div className="mb-30">
                  {/* <span className="top-title">Unified Platform</span> */}
                  {/* <h2>SIM overlay secure banking solution</h2> */}
                  <p>
                    Introducing our Mobile Wallet and contactless cards &
                    QR-based Mobile Money Solutions, a cutting-edge technology
                    platform that provides a secure and convenient way for
                    consumers to make mobile transactions. Consumers can
                    configure their wallets into multiple structures :
                  </p>
                  <ul>
                    <li>
                      Mother and child wallets with transaction and topup
                      configured for each user type
                    </li>
                    <li>Online and offline contactless cards</li>
                  </ul>
                  <p>
                    and make payments using QR code scanning / TapNPay or then a
                    walletID, eliminating the need for cash for online / offline
                    merchants. Our Mobile Wallet solution allows consumers to
                    store, transfer and manage their money using their mobile
                    device, and it can also be integrated with other payment
                    methods such as debit/credit cards and UPI. Our QR-based
                    Mobile Money solution allows consumers to make payments by
                    scanning QR codes at merchants' point of sale terminals,
                    providing a fast and secure way to make transactions.
                  </p>
                  <p>
                    Our Mobile Wallet and QR-based Mobile Money solutions
                    provide an extra layer of security by using advanced
                    encryption and tokenization technology to protect sensitive
                    information. They are also fully compliant with industry
                    standards and regulations, providing consumers and merchants
                    peace of mind when making mobile transactions.
                  </p>
                  <p>
                    Choose our Mobile Wallet and QR-based Mobile Money solutions
                    and experience a new level of security, convenience, and
                    efficiency for all your mobile transactions.
                  </p>
                  <p>
                    Online or In-app M-commerce payments are growing and today
                    are the backbone of the economy going digital. Building a
                    simpler, safer, faster and a lower cost transaction
                    environment here was much needed and we focussed on this and
                    achieved it a few months ago. With the core engine certified
                    by multiple payment schemes.
                  </p>
                </div>

                {/* <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-wallet"></i>
                                            <h3>Split The Necessities</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="single-unified-platform">
                                            <i className="flaticon-id-card"></i>
                                            <h3>Pay For Business</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana integer sagittis, eleifend ipsum dolor sit dolor.</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(MobileWallet);
